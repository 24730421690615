import { DISTANCE_TYPE } from "constants/products/me";
import { getNextID, isFieldDefined } from "services/util/auxiliaryUtils";
import clone from 'fast-copy';

export function handleProductlInputsME(data) {

    let details_inputs = data?.details_inputs;
    let contact_details = details_inputs?.contact_details ?? null;
    let profile = details_inputs?.profile ?? null;
    let space = details_inputs?.space ?? null;
    let distance = details_inputs?.distance ?? null;
    let installation = details_inputs?.installation ?? {};
    let general = details_inputs?.general ?? {};
    let floors = details_inputs?.floors ?? [];


    return {
        solution_type_id: details_inputs?.solution_type_id ?? '',

        //PROFILE
        has_electric_vehicle: profile?.has_electric_vehicle,
        brand_id: profile?.brand_id,
        model_id: profile?.model_id,
        daily_distance_km: profile?.daily_distance_km,

        //SPACE
        parking_type_id: space?.parking_type_id,
        network_connection: space?.network_connection,
        is_client: space?.is_client,
        contracted_power_id: space?.contracted_power_id,
        electric_installation_type_id: space?.electric_installation_type_id,
        box_connection_type_id: space?.box_connection_type_id,
        has_me_adaptation: space?.has_me_adaptation,
        evc_electrical_chain_id: space?.evc_electrical_chain_id,
        nif: space?.nif,

        //DISTANCE
        measurement_type_id: distance?.measurement_type_id,
        house_level: distance?.house_level,
        garage_level: distance?.garage_level,
        value: distance?.value,
        distance_range_id: distance?.distance_range_id,

        // INSTALLATION
        ...installation,
        // GENERAL
        ...general,

        // GARAGE
        floors,

        // SIMULATION
        meter_selected: details_inputs?.meter_selected,

        //PROPOSAL
        name: contact_details.name,
        tax_number: contact_details?.tax_number,
        email: contact_details?.email,
        phone_number: contact_details?.phone_number,
        postal_code: contact_details?.postal_code,
        address: contact_details?.address,
        locality: contact_details?.locality,
        personal_data_yield: contact_details?.personal_data_yield,
    };
}


export const getDistanceRange = (value, measurement_type_id = null, distanceRanges, distance_range_id) => {


    if (isFieldDefined(measurement_type_id) && measurement_type_id === DISTANCE_TYPE.RIGHT_MEASUREMENT) {
        return { value, description: `${value} m` }
    }

    return distanceRanges?.find(item => item.id === parseInt(distance_range_id));

}

export const getFloorInputsDefault = (floors) => {
    return clone({
        floorId: getNextID(floors, 0, 'floorId'),
        location: '',
        parking_spots: '',
        area: '',
        distance_parking_spots_id: '',
        max_distance_parking_spots: '',
        min_distance_parking_spots: '',
        distance_parking_spot_qp_id: '',
        max_distance_parking_spot_qp: '',
        min_distance_parking_spot_qp: '',
        distance_qp_to_stairs: '',
        network_connection: '',
    });
}

export const getFloorInputsDuplicate = (data, floors) => {
    let cData = clone(data)
    return clone({
        floorId: getNextID(floors, 0, 'floorId'),
        location: cData?.location,
        parking_spots: cData?.parking_spots,
        area: cData?.area,
        distance_parking_spots_id: cData?.distance_parking_spots_id,
        max_distance_parking_spots: cData?.max_distance_parking_spots,
        min_distance_parking_spots: cData?.min_distance_parking_spots,
        distance_parking_spot_qp_id: cData?.distance_parking_spot_qp_id,
        max_distance_parking_spot_qp: cData?.max_distance_parking_spot_qp,
        min_distance_parking_spot_qp: cData?.min_distance_parking_spot_qp,
        distance_qp_to_stairs: cData?.distance_qp_to_stairs,
        network_connection: cData?.network_connection,
    });
}

export const setFloorInputsDefault = (floor) => {
    let floorInputsDefault = Object.keys(getFloorInputsDefault(floor));

    for (let key in floor) {
        if (!floorInputsDefault.includes(key)) {
            delete floor[key];
        }
    }
}